import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdAccessibilityNew,
  MdRecycling,
  MdWarning,
  MdDomainVerification,
  MdStackedBarChart,
  MdOutlinePublicOff,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Customer from "views/admin/customer";
import Stats from "views/admin/stats";

function Trials() {
    return <Customer trials={true} />

}
function OriginCheckDisabled() {
    return <Customer originCheckDisabled={true} />

}
function OverUsage() {
    return <Customer overUsage={true} />

}

function Domains() {
    return <Customer domains={true} />

}


const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },

  {
    name: "Customers",
    layout: "/admin",
    icon: <Icon as={MdAccessibilityNew} width='20px' height='20px' color='inherit' />,
    path: "/customer",
    component: Customer,
  },
  {
    name: "Domains",
    layout: "/admin",
    icon: <Icon as={MdDomainVerification} width='20px' height='20px' color='inherit' />,
    path: "/domains",
    component: Domains,
  },

  {
    name: "Trials",
    layout: "/admin",
    icon: <Icon as={MdRecycling} width='20px' height='20px' color='inherit' />,
    path: "/trials",
    component: Trials,
  },
  {
    name: "Over Usage",
    layout: "/admin",
    icon: <Icon as={MdWarning} width='20px' height='20px' color='inherit' />,
    path: "/over-usage",
    component: OverUsage,
  },
  {
    name: "Domain Check Disabled",
    layout: "/admin",
    icon: <Icon as={MdOutlinePublicOff} width='20px' height='20px' color='inherit' />,
    path: "/origin-not-checked",
    component: OriginCheckDisabled,
  },

  {
    name: "Statistics",
    layout: "/admin",
    icon: <Icon as={MdStackedBarChart} width='20px' height='20px' color='inherit' />,
    path: "/statistics",
    component: Stats,
  },


  /*
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  */
];

export default routes;
