import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Grid,
  GridItem,
  Spinner,
  Alert,
  AlertIcon,
  Flex,
  HStack,
  Tag
} from '@chakra-ui/react';
import Card from "components/card/Card";
import { ChevronDown, ChevronUp } from 'lucide-react';

import Config from "config";
const HOST = Config.Host;

// Helper function to convert country code to flag emoji
const getCountryFlag = (countryCode) => {
  if (!countryCode) return '';
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

const IPStatsDashboard = () => {
    const [ipStats, setIpStats] = useState(null);
    const [blocklist, setBlocklist] = useState({ segments: [], singles: [] });
    const [ipLoading, setIpLoading] = useState(true);
    const [ipError, setIpError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          setIpLoading(true);
          try {
            const [statsResponse, blocklistResponse] = await Promise.all([
              fetch(HOST + "/cc/ip-stats?cors=true", {
                credentials: "include",
                method: "POST",
              }),
              fetch(HOST + "/cc/growth/get?cors=true", {
                credentials: "include",
                method: "POST"
              })
            ]);

            if (!statsResponse.ok) throw new Error(`HTTP error! Status: ${statsResponse.status}`);
            const statsData = await statsResponse.json();

            if (!blocklistResponse.ok) throw new Error(`HTTP error! Status: ${blocklistResponse.status}`);
            const blocklistData = await blocklistResponse.json();

            setBlocklist(blocklistData.features.blocked_ips.defaultValue);
            setIpStats(statsData);
          } catch (err) {
            setIpError(err);
          } finally {
            setIpLoading(false);
          }
        };

        fetchData();
      }, []);


      const handleBlock = async (segment) => {
        const segmentToBlock = segment.endsWith('.*') ? segment : segment + '.*';

        try {
          const newBlocklist = {
            ...blocklist,
            segments: [...blocklist.segments, segmentToBlock]
          };

          const response = await fetch(HOST + "/cc/growth/set?cors=true", {
            method: 'POST',
            credentials: "include",
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              key: "blocked_ips",
              value: newBlocklist
            })
          });

          if (!response.ok) {
            throw new Error('Failed to update blocklist');
          }

          setBlocklist(newBlocklist);
        } catch (error) {
          console.error('Failed to block segment:', error);
        }
      };

      const handleUnblock = async (segment) => {
        try {
          const newBlocklist = {
            ...blocklist,
            segments: blocklist.segments.filter(s => s !== segment)
          };

          const response = await fetch(HOST + "/cc/growth/set?cors=true", {
            method: 'POST',
            credentials: "include",
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              key: "blocked_ips",
              value: newBlocklist
            })
          });

          if (!response.ok) {
            throw new Error('Failed to update blocklist');
          }

          setBlocklist(newBlocklist);
        } catch (error) {
          console.error('Failed to unblock segment:', error);
        }
      };


  if (ipLoading) {
    return (
      <Flex justify="center" align="center" height="400px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (ipError) {
    return (
      <Alert status="error">
        <AlertIcon />
        {ipError.message}
      </Alert>
    );
  }

  if (!ipStats) return null;

  const formatNumber = (num) => num.toLocaleString();

  return (
    <Box p={6}>
      {/* Overview Stats */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} mb={6}>
        <Card>
          <Stat p={4}>
            <StatLabel>Top Segments</StatLabel>
            <StatNumber>{ipStats.topSegments?.length || 0}</StatNumber>
          </Stat>
        </Card>
        <Card>
          <Stat p={4}>
            <StatLabel>Days Monitored</StatLabel>
            <StatNumber>{Object.keys(ipStats.attackStats?.days || {}).length}</StatNumber>
          </Stat>
        </Card>
        <Card>
          <Stat p={4}>
            <StatLabel>Suggested Blocks</StatLabel>
            <StatNumber color="red.500">{ipStats.suggestedBlocks?.length || 0}</StatNumber>
          </Stat>
        </Card>
      </SimpleGrid>




      {/* Suggested Blocks */}
      {ipStats.todayTopSegments && ipStats.todayTopSegments.length > 0 && (
  <Card mb={6} p={6}>
    <Text fontSize="lg" fontWeight="semibold" mb={4}>
      Suggested Blocks Today ({ipStats.todayTopSegments.length})
    </Text>
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
      {ipStats.todayTopSegments
        .filter(segment => segment.amount >= 300)
        .map((segInfo) => {
            const isBlocked = blocklist.segments.includes(segInfo.segment + '.*');
            return (
          <Box
            key={segInfo.segment}
            p={4}
            borderRadius="lg"
            bg="red.50"
            borderWidth={1}
            borderColor="red.200"
          >
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              <Text fontFamily="mono" fontSize="sm">{segInfo.segment}</Text>
              <Button
                        size="sm"
                        colorScheme={isBlocked ? "blue" : "red"}
                        onClick={() => isBlocked ?
                          handleUnblock(segInfo.segment + '.*') :
                          handleBlock(segInfo.segment)
                        }
                      >
                      {isBlocked ? 'Unblock' : 'Block'}
                      </Button>

            </Flex>
            <HStack spacing={2} fontSize="sm">
              <Text>{getCountryFlag(segInfo.country)}</Text>
              <Text>{segInfo.country}</Text>
              <Text color="gray.600">({formatNumber(segInfo.amount)} hits)</Text>
              <Text color="gray.600">{segInfo.isp}</Text>
            </HStack>
          </Box>
        )})}
    </SimpleGrid>
  </Card>
)}

{/* Current Blocklist */}
<Card mb={6} p={6}>
        <Text fontSize="lg" fontWeight="semibold" mb={4}>
          Current Blocklist ({blocklist.segments.length})
        </Text>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Segment</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {blocklist.segments.map((segment) => (
              <Tr key={segment}>
                <Td fontFamily="mono">{segment}</Td>
                <Td>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => handleUnblock(segment)}
                  >
                    Unblock
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>

      {/* Modified Suggested Blocks section */}
      {ipStats.todayTopSegments && ipStats.todayTopSegments.length > 0 && (
        <Card mb={6} p={6}>
          <Text fontSize="lg" fontWeight="semibold" mb={4}>
            Suggested Blocks ({ipStats.todayTopSegments.length})
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
            {ipStats.todayTopSegments
              .filter(segment => segment.amount >= 1000)
              .map((segInfo) => {
                const isBlocked = blocklist.segments.includes(segInfo.segment + '.*');
                return (
                  <Box
                    key={segInfo.segment}
                    p={4}
                    borderRadius="lg"
                    bg={isBlocked ? "gray.50" : "red.50"}
                    borderWidth={1}
                    borderColor={isBlocked ? "gray.200" : "red.200"}
                  >
                    <Flex justifyContent="space-between" alignItems="center" mb={2}>
                      <Text fontFamily="mono" fontSize="sm">{segInfo.segment}</Text>
                      <Button
                        size="sm"
                        colorScheme={isBlocked ? "blue" : "red"}
                        onClick={() => isBlocked ?
                          handleUnblock(segInfo.segment + '.*') :
                          handleBlock(segInfo.segment)
                        }
                      >
                      {isBlocked ? 'Unblock' : 'Block'}
                      </Button>
                    </Flex>
                    <HStack spacing={2} fontSize="sm">
                      <Text>{getCountryFlag(segInfo.country)}</Text>
                      <Text>{segInfo.country}</Text>
                      <Text color="gray.600">({formatNumber(segInfo.amount)} hits)</Text>
                      <Text color="gray.600">{segInfo.isp}</Text>
                    </HStack>
                  </Box>
                );
              })}
          </SimpleGrid>
        </Card>
      )}


      {/* Daily Stats */}
      <Card>
        <Accordion allowToggle>
          {Object.entries(ipStats.attackStats?.days || {}).map(([day, data]) => (
            <AccordionItem key={day}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton py={4} px={6}>
                    <Box flex="1" textAlign="left">
                      <Text fontWeight="medium">{day}</Text>
                      <Text fontSize="sm" color="gray.500">
                        {Object.keys(data.segments || {}).length} segments
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4} px={6}>
                    <Box overflowX="auto">
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th>Segment</Th>
                            <Th isNumeric>Hits</Th>
                            <Th>ISP</Th>
                            <Th>Country</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                        {Object.entries(data.segments || {})
  .sort(([, a], [, b]) => b.count - a.count)
  .map(([segment, info]) => {
    const isBlocked = blocklist.segments.includes(segment + '.*');
    return (
    <Tr key={segment}>
      <Td fontFamily="mono">{segment}</Td>
      <Td isNumeric fontWeight="medium">{formatNumber(info.count)}</Td>
      <Td>{info.isp || '-'}</Td>
      <Td>
        <HStack spacing={2}>
          <Text>{getCountryFlag(info.country)}</Text>
          <Text>{info.country || '-'}</Text>
        </HStack>
      </Td>
      <Td>
      <Button
                        size="sm"
                        colorScheme={isBlocked ? "blue" : "red"}
                        onClick={() => isBlocked ?
                          handleUnblock(segment + '.*') :
                          handleBlock(segment)
                        }
                      >
                      {isBlocked ? 'Unblock' : 'Block'}
                      </Button>
        </Td>
    </Tr>
  )})}
                        </Tbody>
                      </Table>
                    </Box>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Card>
    </Box>
  );
};

export default IPStatsDashboard;
