// Chakra imports
import {
    Chart,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    TableContainer,
} from "@chakra-ui/react";
import {
    Menu,
    MenuButton,
    MenuList,
    Input,
    Text,
    Tooltip,
    MenuItem,
    Icon,
    Button,
    Image,
    Flex,
    Spinner,
    ListIcon,
    Modal,
    MenuDivider,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'


import {
    RiArrowDownSFill,
    RiArrowUpSFill,
    RiInfinityFill,
} from "react-icons/ri";
import { ChevronDownIcon, CheckCircleIcon, UpDownIcon } from "@chakra-ui/icons";
import { MdWarning, MdNumbers, MdShield, MdZoomIn } from "react-icons/md";
import InputField from "components/fields/InputField";
import CustomerDetail from "components/misc/CustomerDetail";
import TrialDialog from "components/misc/TrialDialog";
import { Box } from "@chakra-ui/react";
import geo from '../../../world.json'

import IpStats from "./IpStats"

import {
    ZoomableGroup,
    ComposableMap,
    Geographies,
    Geography
  } from "react-simple-maps";



// Assets
// Custom components
import { useEffect, useState } from "react";
import Card from "components/card/Card.js";

import Config from "config";
const HOST = Config.Host;

const isoCountryCodes = {
    "AF": "Afghanistan",
    "AX": "Åland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BQ": "Bonaire, Sint Eustatius and Saba",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CV": "Cabo Verde",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo, Democratic Republic of the",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Côte d'Ivoire",
    "HR": "Croatia",
    "CU": "Cuba",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and McDonald Islands",
    "VA": "Holy See",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran, Islamic Republic of",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea, Democratic People's Republic of",
    "KR": "Korea, Republic of",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People's Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MK": "Macedonia, the former Yugoslav Republic of",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia, Federated States of",
    "MD": "Moldova, Republic of",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestine, State of",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "Romania",
    "RU": "Russian Federation",
    "RW": "Rwanda",
    "BL": "Saint Barthélemy",
    "SH": "Saint Helena, Ascension and Tristan da Cunha",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin (French part)",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten (Dutch part)",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan, Province of China",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "US": "United States",
    "UM": "United States Minor Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela, Bolivarian Republic of",
    "VN": "Viet Nam",
    "VG": "Virgin Islands, British",
    "VI": "Virgin Islands, U.S.",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  };

function formatNumber(num) {
    if (num >= 1000000) {
        return Math.floor(num / 1000000) + "mio";
    } else if (num >= 1000) {
        return Math.floor(num / 1000) + "k";
    }
    return num.toString();
}


function hexToRgb(hex) {
    // Remove the hash character if present
    hex = hex.replace(/^#/, '');

    // Parse the hex value into RGB components
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return [r, g, b];
}

function rgbToHex(rgb) {
    return "#" + rgb.map(component => {
        let hex = component.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }).join('');
}

function getColor(factor, hex, val) {
    if(val <= 0) {
        return "#F0F0F0";
    }
    let baseColor = hexToRgb(hex);


    let darknessFactor = val / factor;

    let darkenedColor = baseColor.map(component => Math.round(component * (1 - darknessFactor)));


    return rgbToHex(darkenedColor);

}


const MouseFollowingOverlay = ({country}) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
      const updateMousePosition = (e) => {
        setPosition({ x: e.clientX, y: e.clientY });
      };

      window.addEventListener('mousemove', updateMousePosition);

      return () => {
        window.removeEventListener('mousemove', updateMousePosition);
      };
    }, []);
    if(!country.name) {
        return null;
    }
    return (
      <Box
        position="fixed"
        top={position.y-100}
        left={position.x}
        width="200px"
        pointerEvents="none"
        zIndex={999}
      >
        <Card
                    justifyContent="center"
                    align="center"
                    direction="column"
                    w="100%"
                    mb="0px"
                >
       <span> <i>{country.name}</i> <b>{formatNumber(country.value)}</b></span>
        </Card>
      </Box>
    );
  };


export default function Stats({ }) {
    const [country, setCountry] = useState({});
    const [data, setData] = useState(false)
    const [gg, setGG] = useState(false)


    useEffect(() => {
        fetch(HOST + "/cc/world_stats?cors=true", {
            credentials: "include",
            method: "POST",
        })
            .then((r) => r.json())
            .then((j) => {
                setData(j)
                // ReWire GEO data
                for(let i = 0; i< geo.objects.world.geometries.length; i++) {
                    geo.objects.world.geometries[i].properties.byCustomer = {
                        value: getValueForCountry(j.byCustomer, geo.objects.world.geometries[i].id)
                    };
                    geo.objects.world.geometries[i].properties.byValidations = {
                        value: getValueForCountry(j.byValidations, geo.objects.world.geometries[i].id)
                    };
                }
                setGG(geo)
            });
    }, []);

    function mapCountryCodes(id) {
        if(id === "Germany") return "DE";

        var found = false;
        var n = "";
        for(let countryCode in isoCountryCodes) {

            if(isoCountryCodes[countryCode] === id) {
                n =  countryCode;
                found = true;
            }
        }
        if(!found) {
            console.log("MISSING Country Map:", id)
        } else {
            //console.log("FOUND", id, n)
            return n;
        }
        return id;
    }
    function getValueForCountry(from, id) {
        if(!from || from.length <= 0) {
            return 0;
        }
        var found = 0;
        from.forEach((c) => {
               if(c._id.country === id) {
                found = c.count;
               }

        })
        return found;

    }

    if (!data || !gg) {
        return (
            <>
                <Flex
                    my="auto"
                    h="100%"
                    align={{ base: "center", xl: "start" }}
                    justify={{ base: "center", xl: "center" }}
                >
                    <Spinner size="xl" />
                </Flex>
            </>
        );
    }


    return (<>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card

                    direction="column"
                    w="100%"
                    mb="0px"
                >

        <Tabs>
  <TabList>
    <Tab>Customers by Country</Tab>
    <Tab>Validations by Country</Tab>
    <Tab>IP Statistics</Tab>

  </TabList>

  <TabPanels width="100%">
    <TabPanel>

    <ComposableMap>
    <ZoomableGroup>
          <Geographies geography={gg}>
            {({ geographies }) => {

              return geographies.map((geo) => {

                return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    //setTooltipContent(`${geo.properties.name}`);
                    setCountry({name: geo.properties.name, value: geo.properties.byCustomer.value});
                    //console.log(getValueForCountry(data.byCustomer, geo.id))
                    console.log(geo.properties.byCustomer.value)
                  }}
                  onMouseLeave={() => {
                    setCountry({})
                  }}
                  style={{
                    default: {
                      fill: getColor(300, "#D8BFD8",geo.properties.byCustomer.value),
                      outline: "none"
                    },
                    hover: {
                      fill: "#9400D3",
                      outline: "none"
                    },
                    pressed: {
                      fill: "#9400D3",
                      outline: "none"
                    }
                  }}
                />
              )})
            }}
          </Geographies>
          </ZoomableGroup>
      </ComposableMap>

      <MouseFollowingOverlay country={country} />

    </TabPanel>
    <TabPanel>


    <ComposableMap>
    <ZoomableGroup>
          <Geographies geography={gg}>
            {({ geographies }) => {

              return geographies.map((geo) => {

                return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    //setTooltipContent(`${geo.properties.name}`);
                    setCountry({name: geo.properties.name, value: geo.properties.byValidations.value});
                    //console.log(getValueForCountry(data.byCustomer, geo.id))
                    console.log(geo.properties.byValidations.value)
                  }}
                  onMouseLeave={() => {
                    setCountry({})
                  }}
                  style={{
                    default: {
                      fill: getColor(2000, "#D8BFD8",geo.properties.byValidations.value),
                      outline: "none"
                    },
                    hover: {
                      fill: "#9400D3",
                      outline: "none"
                    },
                    pressed: {
                      fill: "#9400D3",
                      outline: "none"
                    }
                  }}
                />
              )})
            }}
          </Geographies>
          </ZoomableGroup>
      </ComposableMap>

      <MouseFollowingOverlay country={country} />



    </TabPanel>

    <TabPanel width="100%" maxWidth="100%" p={0}>  {/* Remove padding, set full width */}
    <Box width="100%" maxWidth="100%">
   <IpStats />
   </Box>
          </TabPanel>

  </TabPanels>
</Tabs>



</Card>
        </Box>
        </>)
}
