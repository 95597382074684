import React, { useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Box,
  Input,
  Collapse,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Config from "config";
const HOST = Config.Host;

const ValidationRow = ({ monthData, mode }) => {
  const [open, setOpen] = useState(false);
  const formatNumber = (value) => new Intl.NumberFormat().format(value);

  const totalValue = mode === 'origins'
    ? monthData.items.reduce((sum, item) => sum + item.totalChallenges, 0)
    : monthData.items.reduce((sum, item) => sum + item.value, 0);

  return (
    <>
      <Tr>
        <Td width="40px" px={4}>
          <IconButton
            size="sm"
            variant="ghost"
            color="gray.500"
            _hover={{ bg: 'gray.50' }}
            icon={open ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            onClick={() => setOpen(!open)}
            aria-label={open ? "Collapse row" : "Expand row"}
          />
        </Td>
        <Td fontSize="lg" fontWeight="medium" py={6}>
          {monthData.month.toString().padStart(2, '0')}-{monthData.year}
        </Td>
        <Td isNumeric fontSize="lg" fontWeight="medium" py={6}>
          {formatNumber(totalValue)}
        </Td>
      </Tr>
      <Tr>
        <Td p={0} colSpan={3}>
          <Collapse in={open}>
            <Box py={4} px={8} bg="gray.50">
              <Table size="sm" variant="simple">
                <Thead>
                  <Tr>
                    <Th color="gray.600" fontWeight="medium" fontSize="sm">
                      {mode === 'origins' ? 'Origin' : 'Domain'}
                    </Th>
                    <Th isNumeric color="gray.600" fontWeight="medium" fontSize="sm">
                      {mode === 'origins' ? 'Challenges' : 'Validations'}
                    </Th>
                    {mode === 'origins' && (
                      <Th isNumeric color="gray.600" fontWeight="medium" fontSize="sm">
                        Solved
                      </Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {monthData.items.map((item, index) => (
                    <Tr key={index}>
                      <Td color="gray.700">
                        {mode === 'origins' ? item.origin : item.domain}
                      </Td>
                      <Td isNumeric color="gray.700">
                        {formatNumber(mode === 'origins' ? item.totalChallenges : item.value)}
                      </Td>
                      {mode === 'origins' && (
                        <Td isNumeric color="gray.700">
                          {formatNumber(item.totalSolved)}
                        </Td>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};

const Stats = ({ localId, mode = 'usage' }) => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [domainFilter, setDomainFilter] = useState('');

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = mode === 'origins'
          ? `${HOST}/cc/customers/${localId}/origins?cors=true`
          : `${HOST}/cc/customers/${localId}/usage?cors=true`;

        const response = await fetch(endpoint, {
          credentials: "include",
          method: "POST"
        });
        const data = await response.json();

        // Group by month-year
        const groupedData = data.History.reduce((acc, item) => {
          const key = `${item.month}-${item.year}`;
          if (!acc[key]) {
            acc[key] = {
              month: item.month,
              year: item.year,
              items: [],
            };
          }
          // Handle both data formats
          if (mode === 'origins') {
            acc[key].items.push({
              origin: item.origin,
              totalChallenges: item.totalChallenges,
              totalSolved: item.totalSolved,
            });
          } else {
            acc[key].items.push({
              domain: item.domain,
              value: item.value,
              websiteId: item.websiteId,
            });
          }
          return acc;
        }, {});

        setStats(Object.values(groupedData));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [localId, mode]);

  const filteredStats = stats.map(monthData => ({
    ...monthData,
    items: monthData.items.filter(item => {
      if (!domainFilter) return true;
      const searchField = mode === 'origins' ? item.origin : item.domain;
      try {
        return new RegExp(domainFilter, 'i').test(searchField);
      } catch {
        return true;
      }
    })
  })).filter(monthData => monthData.items.length > 0);

  if (loading) {
    return (
      <Center py={8}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  }

  return (
    <Box>
      <Box mb={6}>
        <Input
          placeholder={`Filter ${mode === 'origins' ? 'origins' : 'domains'} (regex)`}
          value={domainFilter}
          onChange={(e) => setDomainFilter(e.target.value)}
          maxWidth="400px"
          size="lg"
          borderColor="gray.200"
          _placeholder={{ color: 'gray.400' }}
        />
      </Box>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th width="40px"></Th>
              <Th
                color="gray.600"
                fontSize="sm"
                fontWeight="semibold"
                textTransform="uppercase"
                py={4}
              >
                Month
              </Th>
              <Th
                isNumeric
                color="gray.600"
                fontSize="sm"
                fontWeight="semibold"
                textTransform="uppercase"
                py={4}
              >
                {mode === 'origins' ? 'Total Challenges' : 'Total Validations'}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredStats.map((monthData, index) => (
              <ValidationRow key={index} monthData={monthData} mode={mode} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Stats;
